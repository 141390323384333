.qr {
  display: block;
  margin: 0 auto;

  &_image {
    display: block;
    margin: 0 auto;
    width: 70%;
    padding-bottom: 2rem;
  }

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $button-color;
    border: solid $font-color;
    border-width: 0.2rem;
    border-radius: 5rem;

    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    min-width: 250px;
    max-width: 500px;

    margin: 2rem;

    font-size: 1.5rem;
    color: $title-color;

    &_icon {
      width: 5vw;
      margin: 0.7rem;
      max-width: 2.5rem;
    }
  }
}
