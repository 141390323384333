
body {
  font-family: 'Lato', sans-serif;
  line-height: 1.5; // Later can change
  color: $color-black;
}

p {
  font-size: 1.5rem;
  color: $color-black;
}