$color-blue1: #002352;
$color-blue2: #00476a;
$color-teal: #1fa5a0;
$color-blue3: #179bd7;
$color-white: #ffffff;
$color-whitesmoke: #e6e6e9;

$color-black: #000000;

// Old color palette
// $background-color: #40e0d0;
// $button-2: #3acabb;
// $button-color: #33b3a6;
// $font-color: #2d9d92;
// $title-color: #26867d;

// New color palette
$background-color: #242336;
$title-color: white;
$button-color: #2d2b41;
$font-color: #4c7b83;
