.header {
  display: block;
  margin: 1rem;
  // padding: 1rem;

  &_sub {
    display: flex;
    align-items: center;
  }

  &_grid {
    display: grid;
    border-width: 0.3rem;
    border-radius: 0.5rem;

    grid-template-columns: 35% 65%;
    align-items: center;
    justify-content: center;
  }

  &_image {
    width: 110%;
    border-radius: 50%;
  }

  &_paragraph {
    display: block;
    margin: 0 auto;
    padding: 0.5rem;
    width: 80%;

    > * {
      overflow-wrap: break-word;
    }

    > p {
      color: $font-color;
    }

    &_name {
      font-size: 2rem;
      color: $title-color !important;
      font-weight: 800;
    }
  }

  &_bio {
    display: block;
    margin: 0 auto;
    padding: 1rem;
    width: 95%;
    color: $title-color;
  }
}
