@import 'styles/abstracts/variables';

@import 'styles/base/base';
@import 'styles/base/typography';

@import 'styles/components/body';
@import 'styles/components/header';
@import 'styles/components/tabs';
@import 'styles/components/qr';
@import 'styles/components/contact';
@import 'styles/components/footer';

@import 'styles/utils/utils';

.App {
  text-align: center;
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &_CTA {
    display: inline-block;
  }

  &_sub {
    display: block;
    margin: 0 auto;
    max-width: 40rem;
    padding: 0.5rem;
    flex: 1;
  }
}
