*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This redefine 1rem of 16px to become 1rem of 10px
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}